import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import NotFoundImg from "../images/nf.jpeg"

const PageNotFound = ({ location }) => {
  // useEffect(() => {
  //   navigate("/")
  // }, [])
  return (
    <Layout location={location}>
      <Seo title="404: Not found" />
      <section style={{ paddingtop: "3rem" }}>
        <div className="container-fluid not-found-wrap">
          <div className="row">
            <div
              className="col-md-6 relative align-self-cente not-found-img"
              style={{
                backgroundImage: `url(${NotFoundImg})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundPosition: "center left",
                height: "42rem",
              }}
            />
            <div
              className="col-md-6 align-self-center px-5 not-found-txt"
              style={{ padding: "5rem 0" }}
            >
              <h1 className="mt-5">404 - Page Does Not Exist</h1>
              <p className="mb-0">
                This page is unavailable. There could be an error in the URL or
                the page was moved.
              </p>
              <p>Here are a few links to get you back on track.</p>
              <div className="button-404 d-flex mt-5">
                <Link class="btn btn-primary btn-green mr-2" to="/">
                  Home Page
                </Link>
                <Link class="btn btn-primary btn-arrow mr-2" to="/blog/">
                  Blogs
                </Link>
                <Link
                  class="btn btn-primary btn-arrow mr-2"
                  to="/case-studies/"
                >
                  Case Studies
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default PageNotFound
